import styled from "styled-components"
import { MUTED_TEAL, LIGHT_TEAL } from "./colors"

export const StyledContentSection = styled.div`
  margin-bottom: 4em;
`

export const StyledContentHeading = styled.h2`
  font-family: 'Raleway';
  font-weight: 200;
  color: ${MUTED_TEAL};
  padding: .2em 0;
`