import React from "react"
import { StyledContentSection, StyledContentHeading } from "../section-helpers"

const ContactSection = () => (
  <>
	  <a name="contact" />
	  <StyledContentSection>
	    <StyledContentHeading>Contact</StyledContentHeading>
	    <p>Please feel free to write to me at: <a href="mailto:cbgibson@princeton.edu">cbgibson at princeton dot edu</a></p>
	  </StyledContentSection>
  </>
)

export default ContactSection