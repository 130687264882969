import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LandingSection from "../components/sections/landing"
import ResearchSection from "../components/sections/research"
import TeachingSection from "../components/sections/teaching"
import PictSection from "../components/sections/pict"
import ContactSection from "../components/sections/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <LandingSection />
    <ResearchSection />
    <TeachingSection />
    <PictSection />
    <ContactSection />
  </Layout>
)

export default IndexPage
